export const defaultLanguage = "ko";

export const ApiBaseUrl =
  process.env.NEXT_PUBLIC_API_URL ?? "https://api-staging.beautibucks.com";

export const FacebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
export const KakaoAppId =
  process.env.NEXT_PUBLIC_APP_KAKAO ?? "127d4d5ac56320499faa9edaa1c59a73";
export const NaverClientId =
  process.env.NEXT_PUBLIC_APP_NAVER ?? "Jy_deJfvNTK3h4BJcRj4";

export const AppleAppId =
  process.env.NEXT_PUBLIC_APPLE_CLIENT_ID ?? "8UTCFWUCSN";
export const AppleAppRedirectURI =
  process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI ??
  "https://www.beautibucks.com/apple/auth";

export const GoogleAppId =
  process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ??
  "157579299344-nba1svhqqa5mc95ohbegbtba3rl856km.apps.googleusercontent.com";

export const VapidKey =
  process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY ??
  "BGuQxGExGXIyzrmL-Susw99Me652tyzmSPrOD6FaoSxgxFZIYR8u4KvNhGBHO4YPAl13PUnZvij2Q5uJa312GYg";

export const defaultImageUrl = "/beauti_bucks_logo.svg";

export const defaultLogo = "/beauti_bucks_logo.svg";

export const DATE_FORMAT_DEFAULT = "yyyy-MM-dd";
export const DATE_TIME_FORMAT_DEFAULT = "hh:mm a, yyyy-MM-dd";
export const DATE_TIME_FORMAT_YYYYMMDDHHMM = "yyyy-MM-dd, hh:mm a";

export const SIGN_IN_TAB_INDEX = {
  PERSONAL: 0,
  CORPORATE: 1,
};

export const enableSnsLogin = [SIGN_IN_TAB_INDEX.PERSONAL];

export const UserRegisterStatus = {
  ACTIVE: "ACTIVE",
  NOT_ACTIVE: "NOT_ACTIVE",
};

export const UserType = {
  PERSONAL: "PERSONAL",
  CORPORATE: "CORPORATE",
  HEAD_HUNTER: "HEAD_HUNTER",
  CONSULTANT: "CONSULTANT",
  ADMIN: "ADMIN",
};

export const UserSocialType = {
  NORMAL: "NORMAL",
  NAVER: "NAVER",
  KAKAO_TALK: "KAKAO_TALK",
  FACEBOOK: "FACEBOOK",
};

export const UserInformationValidityPeriod = {
  ONE_YEAR: "ONE_YEAR",
  FIVE_YEAR: "FIVE_YEAR",
  UNTIL_MEMBERSHIP_WITHDRAW: "UNTIL_MEMBERSHIP_WITHDRAW",
};

export const StorageKey = {
  language: "bb_locale",
  xToken: "x_token",
  userData: "user_data",
};

export const BannerPage = {
  INTRODUCTION_PAGE: "INTRODUCTION_PAGE",
  HOME_PAGE: "HOME_PAGE",
};

export const BannerPosition = {
  MAIN_BANNER: "MAIN_BANNER",
  RIGHT_SIDE_BANNER: "RIGHT_SIDE_BANNER",
};

export const AdsBannerPosition = {
  LANDING: "LANDING",
  MAIN_BIG: "MAIN_BIG",
  MAIN_RIGHT: "MAIN_RIGHT",
  SLIDE_RIGHT: "SLIDE_RIGHT",
};

export const NoticeStatus = {
  WAITING: "WAITING",
  PROGRESS: "PROGRESS",
  CLOSING: "CLOSING",
  END: "END",
};

export const ResumeStatuKeys = {
  OPEN: "OPEN",
  NOT_OPEN: "NOT_OPEN",
};

export const ImageAcceptType =
  "image/png, image/jpg, image/jpeg, jpg, jpeg, png";

export const DialogType = {
  CONFIRM: "CONFIRM",
  ALERT: "ALERT",
};

export const CertificateMethods = {
  PHONE: "PHONE",
  EMAIL: "EMAIL",
};

export const SEARCH_TARGET = {
  JOB: "JOB",
  COMPANY: "COMPANY",
};

export const ADS_TARGET = {
  BANNER: "BANNER",
  JOB: "JOB",
};

export const InquiryType = {
  VIDEO: "VIDEO",
  ADS_JOB: "ADS_JOB",
  ADS_BANNER: "ADS_BANNER",
};

export const InquiryContactType = {
  PHONE_EMAIL: "PHONE_EMAIL",
  PHONE_CALL: "PHONE_CALL",
  EMAIL: "EMAIL",
};

export const PAYAPP_STATUS = {
  PROCESSING: "PROCESSING",
  SUCCEED: "SUCCEED",
  CANCELED: "CANCELED",
  CANCEL_AFTER_PAYMENT: "CANCEL_AFTER_PAYMENT",
};

export const PAYAPP_FILTER_TYPE = {
  RECRUTIMENT_ADS: "RECRUTIMENT_ADS",
  BANNER_ADS: "BANNER_ADS",
};

export const ADS_FILTER_TYPE = {
  RECRUTIMENT_ADS: "RECRUTIMENT_ADS",
  BANNER_ADS: "BANNER_ADS",
};

export const FAV_TYPE = {
  CORPORATE: "PERSONAL_TO_CORPORATE",
  PERSONAL: "CORPORATE_TO_PERSONAL",
  JOB: "PERSONAL_TO_JOB",
};

export const ReviewQuestionType = {
  REVIEW_CORPORATE: "REVIEW_CORPORATE",
  PERSONAL_REVIEW_INTERVIEW: "PERSONAL_REVIEW_INTERVIEW",
  CORPORATE_REVIEW_INTERVIEW: "CORPORATE_REVIEW_INTERVIEW",
};
export const DOMAIN_LIST = [
  "naver.com",
  "hanmail.net",
  "nate.com",
  "daum.net",
  "gmail.com",
  "dreamwiz.com",
];

export const ADS_STATUS = {
  NONE: "NONE",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  CANCEL: "CANCEL",
};

export const inputNumberInvalidChars = ["-", "+", "e", "E"];
