export function IconListOfApply({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="fi_2983677" clipPath="url(#clip0_2469_37404)">
        <g id="Group">
          <path
            id="Vector"
            d="M22.4126 7.58359C21.899 7.06965 21.0826 7.03735 20.5305 7.48721C20.4507 7.55227 20.5125 7.49494 19.2969 8.71055L22.4523 11.866L23.5864 10.7461C24.1347 10.1979 24.1347 9.30573 23.5864 8.75738L22.4126 7.58359Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M12.8284 15.2413L10.9722 19.2189C10.8472 19.4869 10.9031 19.8043 11.1122 20.0134C11.3213 20.2225 11.6389 20.2784 11.9067 20.1534L15.8843 18.2972C16.0416 18.2237 15.5652 18.6696 21.454 12.8543L18.3048 9.70508C12.4662 15.5438 12.9033 15.0809 12.8284 15.2413Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M17.0683 19.1611C16.8961 19.3312 16.6976 19.4692 16.4784 19.5715L12.5009 21.4277C11.7187 21.7927 10.7632 21.6538 10.1174 21.0078C9.49247 20.383 9.32367 19.4251 9.69736 18.6242L11.5536 14.6466C11.6578 14.4232 11.7991 14.2215 11.9734 14.0472L17.9531 8.06747V2.10938C17.9531 0.946266 17.0069 0 15.8438 0H2.10938C0.946266 0 0 0.946266 0 2.10938V21.8906C0 23.0537 0.946266 24 2.10938 24H15.8438C17.0069 24 17.9531 23.0537 17.9531 21.8906V18.2873L17.0683 19.1611ZM3.75005 4.54688H14.25C14.6384 4.54688 14.9532 4.86169 14.9532 5.25C14.9532 5.63831 14.6384 5.95312 14.25 5.95312H3.75005C3.36173 5.95312 3.04692 5.63831 3.04692 5.25C3.04692 4.86169 3.36173 4.54688 3.75005 4.54688ZM3.75005 8.29688H12C12.3884 8.29688 12.7032 8.61169 12.7032 9C12.7032 9.38831 12.3884 9.70312 12 9.70312H3.75005C3.36173 9.70312 3.04692 9.38831 3.04692 9C3.04692 8.61169 3.36173 8.29688 3.75005 8.29688ZM3.04692 12.75C3.04692 12.3617 3.36173 12.0469 3.75005 12.0469H9.75005C10.1384 12.0469 10.4532 12.3617 10.4532 12.75C10.4532 13.1383 10.1384 13.4531 9.75005 13.4531H3.75005C3.36173 13.4531 3.04692 13.1383 3.04692 12.75Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
